// /////////////////////////////////////////////////////////////////////////////
// Author: Richmond Bautista ///////////////////////////////////////////////////
// For deployment: DEC2024 /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// /////////////////////////////////////////////////////////////////////////////
// COLOR SET ///////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

$-clr-a: #ffffff; // white
$-clr-b: #000000; // black
$-clr-c: #808080; // gray
$-clr-d: #f5f4ee; // white variant

// Primary Color
$-clr-01: #fd7e14; // $orange-500

// Secondary Color
$-clr-02: #20c997; // $teal-500

// Complementary Color
$-clr-03: #0d6efd; // $blue-500

// Monochromatic Color
$-clr-04: #ca6510; // $orange-600

// Analogous Colors
$-clr-05: #ffc107; // $yellow-500
$-clr-06: #dc3545; // $red-500

// Triadic Colors
$-clr-07: #0dcaf0; // $cyan-500
$-clr-08: #6f42c1; // $purple-500

// Tetradic Colors
$-clr-09: $-clr-02; // $teal-500
$-clr-10: $-clr-07; // $cyan-500
$-clr-11: #d63384; // $pink-500

$-clr-success: "#14a44d";
$-clr-error: "#dc4c64";
$-clr-warning: "#e4a11b";
$-clr-info: "#0275d8";

// /////////////////////////////////////////////////////////////////////////////
// TEXT COLORS /////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

$txt-a: $-clr-a;
$txt-b: $-clr-b;
$txt-c: $-clr-c;
$txt-d: $-clr-d;

$txt-01: $-clr-01;
$txt-02: $-clr-02;
$txt-03: $-clr-03;
$txt-04: $-clr-04;
$txt-05: $-clr-05;
$txt-06: $-clr-06;
$txt-07: $-clr-07;
$txt-08: $-clr-08;
$txt-09: $-clr-09;
$txt-10: $-clr-10;
$txt-11: $-clr-11;

.txt-success {
  color: $-clr-success;
}

.txt-error {
  color: $-clr-error;
}

.txt-warning {
  color: $-clr-warning;
}

.txt-info {
  color: $-clr-info;
}

// /////////////////////////////////////////////////////////////////////////////
// BACKGROUND COLORS ///////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

$bgd-a: $-clr-a;
$bgd-b: $-clr-b;
$bgd-c: $-clr-c;
$bgd-d: $-clr-d;

$bgd-01: $-clr-01;
$bgd-02: $-clr-02;
$bgd-03: $-clr-03;
$bgd-04: $-clr-04;
$bgd-05: $-clr-05;
$bgd-06: $-clr-06;
$bgd-07: $-clr-07;
$bgd-08: $-clr-08;
$bgd-09: $-clr-09;
$bgd-10: $-clr-10;
$bgd-11: $-clr-11;

.bgd-success {
  background-color: $-clr-success;
}

.bgd-error {
  background-color: $-clr-error;
}

.bgd-warning {
  background-color: $-clr-warning;
}

.bgd-info {
  background-color: $-clr-info;
}

// /////////////////////////////////////////////////////////////////////////////
// SHADOW COLORS ///////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

$box-shadow-card: rgba(0, 0, 0, 0.12) 0px 1px 3px,
  rgba(0, 0, 0, 0.24) 0px 1px 2px;

$box-shadow-card-hover: rgba(0, 0, 0, 0.25) 0px 14px 28px,
  rgba(0, 0, 0, 0.22) 0px 10px 10px;
