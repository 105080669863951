@import "../../assets/scss/Styles.scss";

body {
  max-width: 100%;
  overflow-x: hidden;
}

.verify-page {
  width: 100vw;
  max-width: 100vw;
  min-height: 100vh;

  display: flex;

  overflow-x: unset;

  // background-color: green;

  .side-nav {
    width: 300px;
    max-height: 100vh;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    // transition: width 1s ease-in-out;

    position: -webkit-sticky;
    position: sticky;
    top: 0;

    a,
    p,
    svg,
    span {
      color: $txt-a;
    }

    .user-info {
      // padding: 20px;
      // width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .big-icon {
        min-width: 100px;
        min-height: 100px;
        max-width: 100px;
        max-height: 100px;

        margin: 40px 40px 10px 40px;
      }

      .text-center {
        margin: 0;
        font-weight: bold;
      }
    }

    .btn-side {
      background-color: transparent;
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 20px;
      text-decoration: none;
      overflow: hidden;

      width: 100%;
      border: none;
    }

    .btn-side:hover {
      background-color: #244f7d1c;
    }

    .category {
      padding: 10px 60px;
    }

    .btn-menu {
      background-color: transparent;
      border: none;
      display: flex;
      justify-content: flex-start;

      align-self: flex-end;
      justify-self: flex-end;
      padding: 0;
      width: 100%;

      p {
        margin: 0;
      }
    }

    .btn-menu-close {
      justify-content: center;
      padding-left: 0px;
    }

    .link-text {
      margin: 0 !important;
      padding-left: 16px;
      cursor: pointer;
    }

    .area {
      margin: 0 !important;
      padding-left: 16px * 3.5;
    }

    .data {
      margin: 0 !important;
      padding-left: 16px * 6;
    }

    .text-copyright {
      padding: 0 20px 20px 20px;
      margin: 0;
    }
  }

  .side-nav-close {
    width: 60px;

    .user-info {
      // padding: 20px;
      display: flex;
      // align-items: center;
      justify-content: center;
      // background-color: green;
      padding: 10px 0;

      .small-icon {
        max-width: 32px;
        max-height: 32px;
        // align-self: center;
        // justify-self: center;
        // min-width: 100px;
        margin: 0;
      }

      .text-center {
        margin: 0;
        font-weight: bold;
      }
    }
  }

  .main-pane {
    width: calc(100vw - 312px);
    min-height: 100vh;
    background-color: $bgd-a;
    // background-color: red;

    // transition: width 1s ease-in-out;

    padding: 0 18px;
    // margin: 0;

    .actions {
      position: -webkit-sticky;
      position: sticky;
      right: 0;
      background-color: white;
    }

    .table-set {
      // max-width: calc(100% - 30px);

      // margin-left: 20px;
      display: flex;
      flex-direction: column;
      align-items: left;
      justify-content: left;

      max-width: 100%;
      // overflow-x: scroll;

      .table-box {
        min-height: 120vh;
        max-height: 120vh;
        padding: 20px 0;
        // background-color: red;
      }
    }
  }

  .main-pane-wide {
    width: calc(100vw - 60px);
  }
  //   min-width: calc(100vw - 15px);

  //   .verification-ui {
  //     height: 150vh * (12 + 1); // !!! 13 is the number of sections in the page

  //   }

  //   a,
  //   p,
  //   nav,
  //   svg,
  //   span,
  //   button {
  //     color: white;
  //   }

  //   a:hover {
  //     color: white;
  //   }

  // ///////////////////////////////////////////////////////////////////////////

  // $width-max: 350px;
  // $width-min: 60px;

  // .sidenav {
  //   min-height: 100vh;
  //   height: 100%;
  //   max-width: $width-max !important;
  //   transition: width 1s ease-in-out;
  //   background-color: rgb(10, 25, 41);

  //   .fixednav {
  //     width: $width-max;
  //     height: 100vh;

  //     display: flex;
  //     flex-direction: column;
  //     justify-content: space-between;

  //     position: -webkit-sticky;
  //     position: sticky;
  //     top: 0;

  //     padding: 20px 0;

  //     transition: width 1s ease-in-out;

  //     .big-icon {
  //       width: 100px;
  //       height: 100px;
  //       margin-bottom: 20px;
  //     }

  //     .small-icon {
  //       display: flex;
  //       align-items: center;
  //       justify-content: center;
  //       margin-bottom: 10px;
  //       width: 100%;
  //     }
  //   }

  //   .fixednavclosed {
  //     transition: width 1s ease-in-out;
  //     width: $width-min;
  //   }
  // }

  // .sidenavclosed {
  //   transition: width 1s ease-in-out;
  //   width: $width-min;
  // }

  // /////////////////////////////////////////////////////////////////////////////

  // .user-info {
  //   display: flex;
  //   flex-direction: column;
  //   align-items: center;
  //   justify-content: center;
  //   margin: 20px 0;

  //   .text-center {
  //     margin: 0;
  //   }
  // }

  // .areas {
  //   padding-left: 30px;
  //   font-weight: bold;
  // }

  // .data {
  //   padding-left: 70px !important;
  // }

  // .nav-link {
  //   padding: 2px 0;
  // }

  // .arrow {
  //   bottom: 2px;
  // }

  // /////////////////////////////////////////////////////////////////////////////

  //   .main {
  //     min-height: 100vh;
  //     width: calc(100vw - $width-max - 15px);

  //     .MUIBox-root {
  //       color: red !important;
  //     }

  //   }

  //   .mainclosed {
  //     //   width: calc(100vw - $width-min - 15px);
  //     //   transition: width 1s ease-in-out;
  //   }
}
