// /////////////////////////////////////////////////////////////////////////////
// Author: Richmond Bautista ///////////////////////////////////////////////////
// For deployment: DEC2024 /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

@import "./Colors.scss";

$theme-colors: (
  "primary": $bgd-01,
  "secondary": $bgd-02,
  "success": #14a44d,
  "info": #0275d8,
  "warning": #e4a11b,
  "danger": #dc4c64,
  "light": #fbfbfb,
  "dark": #332d2d,
);

.btn.btn-primary,
.btn.btn-secondary,
.btn.btn-success,
.btn.btn-info,
.btn.btn-warning,
.btn.btn-danger,
.btn.btn-dark {
  color: $txt-a;
}

.btn.btn-primary:hover,
.btn.btn-secondary:hover,
.btn.btn-success:hover,
.btn.btn-info:hover,
.btn.btn-warning:hover,
.btn.btn-danger:hover,
.btn.btn-dark:hover {
  filter: brightness(0.9);
  color: $txt-a;
}

.btn.btn-primary:focus-visible,
.btn.btn-secondary:focus-visible,
.btn.btn-success:focus-visible,
.btn.btn-info:focus-visible,
.btn.btn-warning:focus-visible,
.btn.btn-danger:focus-visible,
.btn.btn-dark:focus-visible {
  filter: brightness(1);
  color: $txt-a;
}

@import "../../../node_modules/bootstrap/scss/bootstrap.scss";
