// TODO Update styles
@import "../../assets/scss/Styles.scss";

#login-page {
  display: flex;
  align-items: center;
  justify-content: center;

  .form-box {
    background: $bgd-d;
    max-width: 300px;
    min-height: 300px;
    overflow: hidden;
    border-radius: 16px;
    color: $bgd-b;
  }

  .form {
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 32px 24px 24px;
    gap: 16px;
    text-align: center;
  }

  /*Form text*/
  .title {
    font-weight: bold;
    font-size: 1.6rem;
    font-family: $font-fam-title;
  }

  .subtitle {
    font-size: 1rem;
    color: $txt-c;
  }

  /*Inputs box*/
  .form-container {
    overflow: hidden;
    border-radius: 8px;
    background-color: $bgd-a;
    margin: 1rem 0 0.5rem;
    width: 100%;
  }

  .input {
    background: none;
    border: 0;
    outline: 0;
    height: 40px;
    width: 100%;
    border-bottom: 1px solid $bgd-d;
    font-size: 0.9rem;
    padding: 8px 15px;
  }

  .form-section {
    padding: 16px;
    font-size: 0.85rem;
    background-color: $bgd-d;
    box-shadow: $bgd-b 0 -1px;
  }

  .form-section a {
    // font-weight: bold;
    color: $txt-01;
    transition: color 0.3s ease;
  }

  .form-section a:hover {
    color: $txt-02;
    text-decoration: underline;
  }

  /*Button*/
  .form button {
    background-color: $bgd-01;
    color: $txt-a;
    border: 0;
    border-radius: 8px;
    padding: 10px 16px;
    font-size: 1rem;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .form button:hover {
    background-color: $bgd-02;
  }

  .teamlink {
    display: flex;
    gap: 4px;
  }
}
