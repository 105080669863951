// /////////////////////////////////////////////////////////////////////////////
// Author: Richmond Bautista ///////////////////////////////////////////////////
// For deployment: DEC2024 /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// File Browser ////////////////////////////////////////////////////////////////

// TODO Use standard colors
input[type="file"]::file-selector-button {
  margin: 6px;
  margin-right: 20px;
  border: none;
  background: #0275d8;
  padding: 6px 12px;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s ease-in-out;
}

input[type="file"]::file-selector-button:hover {
  filter: brightness(0.9);
}
