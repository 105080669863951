@import "./Styles.scss";
@import "./Colors.scss";

// ! TODO Cardshadow

.form-design {
  min-width: calc(100vw - 20px);
  min-height: 100vh;
  padding: 20px;
  display: flex;
  justify-content: center;

  #video,
  #webcam {
    width: calc(100vw - 70px);
  }

  .v-bar {
    max-width: calc(100vw - 40px);

    display: flex;
    flex-direction: column;
    align-self: center;
    gap: 10px;
  }

  .title {
    color: $txt-01;
    font-weight: bold;
    text-align: center;
    font-size: 20px; // TODO
    margin-top: 0px !important;

    position: sticky;
    // background-color: $bgd-a;
    top: 0;
  }

  .card-body,
  .spacer {
    display: flex;
    flex-direction: column;
    gap: 10px;

    p {
      margin: 0;
    }
  }

  .indent {
    margin-left: 20px;
  }
}

.btn-group {
  width: 100%;
  display: flex;
  flex-direction: row;
}

.w-70 {
  min-width: 70% !important;
}

@media (min-width: 768px) {
  .form-design {
    padding: 20px 0;

    #video,
    #webcam {
      width: calc(30vw - 35px);
    }

    .v-bar {
      width: 30vw;

      display: flex;
      flex-direction: column;
      align-self: center;
      gap: 10px;
    }
  }
}
