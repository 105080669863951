// TODO Update styles
@import "../../assets/scss/Styles.scss";

// /////////////////////////////////////////////////////////////////////////////

.btn-logout-center {
  border-radius: $border-radius !important;
}

.btn-logout-right {
  border-radius: 0 $border-radius $border-radius 0 !important;
}

// /////////////////////////////////////////////////////////////////////////////
// Page Transition Styles //////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

#page-transition {
  position: relative;
  margin: 0px !important;
  padding: 0px !important;

  .page {
    position: absolute;
  }

  .page-enter {
    opacity: 0;
    transform: scale(1.1);
  }

  .page-enter-active {
    opacity: 1;
    transform: scale(1);
    transition: opacity 300ms, transform 300ms;
  }

  .page-exit {
    opacity: 1;
    transform: scale(1);
  }

  .page-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
  }
}
