// /////////////////////////////////////////////////////////////////////////////
// Author: Richmond Bautista ///////////////////////////////////////////////////
// For deployment: DEC2024 /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// NOTE Maximum of one style per class /////////////////////////////////////////

@import "./Fonts.scss";

.avz-fullscreen {
  width: 100dvw;
  height: 100dvh;
}

.avz-border-radius {
  border-radius: 6px;
}

// ! Possible duplicate of font-fam-title
.avz-title {
  font-family: "Arsenal";
}

// ! Possible duplicate of font-fam-subtitle
.avz-subtitle {
  font-family: "Arsenal";
}

.avz-glass {
  background: rgba(255, 255, 255, 0.25);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(4px);
  -webkit-backdrop-filter: blur(4px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
