@import "../../assets/scss/Styles.scss";

#title-bar {
  display: flex;
  flex-direction: column;
  align-items: center;

  min-width: 90%;
  max-width: 90%;
  align-self: center;

  #logo-bar {
    display: flex;
    gap: 10px;

    .splash-icon {
      max-width: 44px;
      max-height: 44px;
    }
  }

  #txt-title {
    text-align: center;
    margin-top: 10px;
    color: $txt-b;
    font-family: $font-fam-title;
    font-weight: bold;
  }

  .category {
    color: $txt-01;
    font-weight: bold;
    text-align: center;
    font-size: 20px; // TODO
    margin-top: 0px !important;

    position: sticky;
    top: 0;
    background-color: $bgd-a;
  }
}

#encode-page {
  width: 100vw;
  min-height: 100vh;
  padding-top: 20px;
  padding-bottom: 20px;

  display: flex;
  flex-direction: column;
  align-items: center;

  #vertically {
    max-width: 95vw;

    display: flex;
    flex-direction: column;
    align-items: center;

    #add-profile {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .add-res {
        padding: 0;
        margin: 0;

        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        .add-profile,
        .view-profile {
          background-color: white;
          min-width: 90%;
          max-width: 90%;
          // min-height: 200px;
          border-radius: 10px;
          box-shadow: $box-shadow-card-hover;
        }

        .add-profile {
          display: flex;
          flex-direction: row;
          align-items: center;
          justify-content: space-between;

          // gap: 10px;
          padding: 20px;

          margin-top: 20px;
          // min-height: 220px;
        }

        .view-profile {
          padding: 20px;
        }

        .name-bar {
          display: flex;
          flex-direction: row;
          align-items: stretch !important;
        }

        #farmer-icon {
          // width: 126px;
          width: 40%;
          align-self: center;
        }
      }

      .farmer-icon {
        height: 128px;
        max-height: 128px;
      }

      .temp {
        width: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        padding-top: 20px;
        gap: 6px;

        .tmp-btn {
          width: 100%;
        }
      }

      .custum-file-upload {
        margin-top: 50px;

        height: 200px;
        width: 300px;
        display: flex;
        flex-direction: column;
        align-items: space-between;
        gap: 20px;
        cursor: pointer;
        align-items: center;
        justify-content: center;
        border: 2px dashed #cacaca;
        background-color: rgba(255, 255, 255, 1);
        padding: 1.5rem;
        border-radius: 10px;
        box-shadow: $box-shadow-card-hover;
      }

      .custum-file-upload .icon {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custum-file-upload .icon svg {
        height: 80px;
        fill: rgba(75, 85, 99, 1);
      }

      .custum-file-upload .text {
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .custum-file-upload .text span {
        font-weight: 400;
        color: rgba(75, 85, 99, 1);
      }

      .custum-file-upload input {
        display: none;
      }
    }
  }
}

@media (min-width: 768px) {
  #encode-page {
    #vertically {
      max-width: 30vw;
    }
  }
}

@media (max-width: 768px) {
  #farmer-icon {
    width: 50%;
  }
}

.btn-group-profile {
  min-height: 50px;
  gap: 10px;
  width: 50%;
  display: flex;
  flex-direction: column !important;

  .btn {
    height: 100% !important;
    border-radius: 10px !important;
  }
}

.profile-name,
.profile {
  padding: 0;
  margin: 0;
  color: $txt-b;

  font-family: $font-fam-title;
  font-weight: bold;
}

.profile-name {
  font-weight: bold;
  font-size: 32px;
}

@media (max-width: 768px) {
  .profile-name {
    font-size: 24px;
  }
}

.profile {
  font-size: 16px;
  margin-bottom: 16px;
}

.status-title {
  font-style: italic;
}

.status {
  font-weight: bold;
  margin-bottom: 8px !important;
}

.btn-edit {
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
}

.accordion-body-mid {
  padding: 0 !important;
}

.content-holder {
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
}

@media (min-width: 768px) {
  .add-profile,
  .view-profile {
    max-width: 24%;
  }
}

.machine-list {
  padding: 20px;
}

/* Content ********************************************************************/

.item-1 {
  padding-bottom: 20px;
}

.machine-content {
  min-width: 300px;
  padding: 0 25px;
}

.text-row {
  margin: 0;
  padding: 2px 0;
  display: flex;
  flex-direction: row;
}

.category {
  margin: 0;
  padding: 0;
  min-width: 50%;
}

.value {
  margin: 0;
  padding: 0;
  font-weight: bold;
}

.btn-group-encode {
  margin-bottom: 10px;
}

.ope-bar {
  display: flex;
  flex-direction: column;
  padding: 20px 10px 10px 10px;
  gap: 10px;
}

.ope-btn-bar {
  width: 100%;
  margin-top: 20px;

  display: flex;
  justify-content: stretch;
  align-items: stretch;
  gap: 6px;
  // flex-grow: 1;

  button {
    padding: 4px 0 !important;
    margin: 0 !important;
    width: 33.333%;
    color: white;
    font-size: 16px;
  }

  button:hover {
    color: antiquewhite;
  }

  // .btn-upload {
  //   border-radius: 10px 0 0 10px !important;
  // }

  // .btn-edit {
  //   border-radius: 0 !important;
  //   height: 100% !important;
  // }

  // .btn-delete {
  //   border-radius: 0 10px 10px 0 !important;
  // }
}

.ope-btn {
  width: 100%;
}

.list {
  padding: 20px;
}
