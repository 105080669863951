// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: April 01, 2024
// Last Clean: April 01, 2024

// /////////////////////////////////////////////////////////////////////////////

@import "../../assets/scss/Styles.scss";

// /////////////////////////////////////////////////////////////////////////////
// Splash Screen Styling ///////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

#splash-screen {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  min-width: 100dvw;
  min-height: 100dvh;

  #logo-bar {
    display: flex;
    gap: 10px;

    margin-bottom: 10px;

    $splash-icon-size: 54px;

    .splash-icon {
      min-width: $splash-icon-size;
      min-height: $splash-icon-size;
      width: $splash-icon-size;
      height: $splash-icon-size;
      max-width: $splash-icon-size;
      max-height: $splash-icon-size;
    }
  }

  #title-bar {
    display: flex;
    flex-direction: column;
    align-items: center;

    #txt-title {
      max-width: 100vw;
      text-align: center;
    }
  }
}

@media (min-width: 768px) {
  #splash-screen {
    #logo-bar {
      $splash-icon-size: 64px;

      .splash-icon {
        min-width: $splash-icon-size;
        min-height: $splash-icon-size;
        width: $splash-icon-size;
        height: $splash-icon-size;
        max-width: $splash-icon-size;
        max-height: $splash-icon-size;
      }
    }

    #title-bar {
      #txt-title {
        max-width: 60vw;
      }
    }
  }
}
