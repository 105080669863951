@import "../../assets/scss/Styles.scss";
// /////////////////////////////////////////////////////////////////////////////
// ERROR PAGE //////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

.error-page {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: center;
  gap: 20px;

  margin: 0 !important;
  padding: 0 !important;

  .title {
    color: #333;
    font-weight: bold;
  }

  .message {
    color: #777;
    max-width: 100%;
    margin: 0 20px;
  }

  .button {
    color: #090909;
    padding: 0.7em 1.7em;
    border-radius: 0.5em;
    background: #e8e8e8;
    cursor: pointer;
    border: 1px solid #e8e8e8;
    transition: all 0.3s;
    box-shadow: 6px 6px 12px #c5c5c5, -6px -6px 12px #ffffff;
  }

  .button:active {
    color: #333;
    box-shadow: inset 4px 4px 12px #c5c5c5, inset -4px -4px 12px #ffffff;
  }
}
