// <!-------------------------------------------------------------------------->
// <!-- M3DAS Web App Phase 3 Source Code ------------------------------------->
// <!-- Programmer: Richmond Bautista  Website: https://rbautista.pro --------->
// <!-- Version and Deployment Information: v3.40 ----------------------------->
// <!-------------------------------------------------------------------------->

@import "./Colors.scss";
@import "./Fonts.scss";
@import "./Class.scss";
@import "./Default.scss";
@import "./Bootstrap.scss";

// /////////////////////////////////////////////////////////////////////////////

@tailwind base;
@tailwind components;
@tailwind utilities;

// /////////////////////////////////////////////////////////////////////////////
// Some Important Notes ////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// Default media query breakpoints
// $breakpoints: ( xs: 0, sm: 576px, md: 768px, lg: 992px, xl: 1200px );

// Default mobile //////////////////////////////////////////////////////////////
// Customize this for large screens ////////////////////////////////////////////
// Version 1.0 /////////////////////////////////////////////////////////////////
// @media (min-width: 768px) {}

// /////////////////////////////////////////////////////////////////////////////
// Styling /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

* {
  font-family: "Poppins";
}

// Font Family Constants ///////////////////////////////////////////////////////

$font-fam-title: "Arsenal";
$font-fam-subtitle: "Arsenal";

// /////////////////////////////////////////////////////////////////////////////
// Main Background Colors //////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

.main-bg-colored {
  background: linear-gradient(-45deg, $bgd-01, $bgd-01, $bgd-09, $bgd-09);
}

.main-bg-form {
  background: linear-gradient(141deg, #ccc 25%, #eee 40%, #ddd 55%);
}

.main-bg-animated {
  background: linear-gradient(-45deg, $bgd-11, $bgd-01, $bgd-09, $bgd-11);
  animation: gradient 15s ease infinite;
  background-size: 400% 400%;

  @keyframes gradient {
    0% {
      background-position: 0% 50%;
    }
    50% {
      background-position: 100% 50%;
    }
    100% {
      background-position: 0% 50%;
    }
  }
}

// /////////////////////////////////////////////////////////////////////////////
// External Libraries //////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

.swal2-title {
  font-family: $font-fam-title;
}
