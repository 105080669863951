// /////////////////////////////////////////////////////////////////////////////
// Author: Richmond Bautista ///////////////////////////////////////////////////
// For deployment: DEC2024 /////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

// This styling is used only for font-face declaration.

// Available Fonts:
// - Metal Gear
// - PT Serif
// - Poppins
// - Arsenal

// /////////////////////////////////////////////////////////////////////////////
// Metal Gear //////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: "Metal Gear";
  src: url("../../assets/font/MetalGear.woff2") format("woff2"),
    url("../../assets/font/MetalGear.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

// /////////////////////////////////////////////////////////////////////////////
// PT Serif ////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: "PT Serif";
  src: url("../../assets/font/PTSerif-Regular.woff2") format("woff2"),
    url("../../assets/font/PTSerif-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PT Serif";
  src: url("../../assets/font/PTSerif-Bold.woff2") format("woff2"),
    url("../../assets/font/PTSerif-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "PT Serif";
  src: url("../../assets/font/PTSerif-Italic.woff2") format("woff2"),
    url("../../assets/font/PTSerif-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "PT Serif";
  src: url("../../assets/font/PTSerif-BoldItalic.woff2") format("woff2"),
    url("../../assets/font/PTSerif-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

// /////////////////////////////////////////////////////////////////////////////
// Poppins /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Regular.woff2") format("woff2"),
    url("../../assets/font/Poppins-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-SemiBold.woff2") format("woff2"),
    url("../../assets/font/Poppins-SemiBold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Thin.woff2") format("woff2"),
    url("../../assets/font/Poppins-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Black.woff2") format("woff2"),
    url("../../assets/font/Poppins-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-ThinItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-MediumItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-MediumItalic.woff") format("woff");
  font-weight: 500;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-BlackItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-SemiBoldItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-SemiBoldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Medium.woff2") format("woff2"),
    url("../../assets/font/Poppins-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Italic.woff2") format("woff2"),
    url("../../assets/font/Poppins-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-ExtraBold.woff2") format("woff2"),
    url("../../assets/font/Poppins-ExtraBold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-ExtraBoldItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-ExtraBoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-ExtraLight.woff2") format("woff2"),
    url("../../assets/font/Poppins-ExtraLight.woff") format("woff");
  font-weight: 200;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-ExtraLightItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-ExtraLightItalic.woff") format("woff");
  font-weight: 200;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-BoldItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Bold.woff2") format("woff2"),
    url("../../assets/font/Poppins-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-LightItalic.woff2") format("woff2"),
    url("../../assets/font/Poppins-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Poppins";
  src: url("../../assets/font/Poppins-Light.woff2") format("woff2"),
    url("../../assets/font/Poppins-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

// /////////////////////////////////////////////////////////////////////////////
// Arsenal /////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

@font-face {
  font-family: "Arsenal";
  src: url("../../assets/font/Arsenal-BoldItalic.woff2") format("woff2"),
    url("../../assets/font/Arsenal-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Arsenal";
  src: url("../../assets/font/Arsenal-Bold.woff2") format("woff2"),
    url("../../assets/font/Arsenal-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Arsenal";
  src: url("../../assets/font/Arsenal-Italic.woff2") format("woff2"),
    url("../../assets/font/Arsenal-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Arsenal";
  src: url("../../assets/font/Arsenal-Regular.woff2") format("woff2"),
    url("../../assets/font/Arsenal-Regular.woff") format("woff");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
