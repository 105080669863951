// /////////////////////////////////////////////////////////////////////////////

// M3DAS Web App Source Code
// Author: Richmond Bautista
// Since: April 01, 2024
// Last Clean:

// /////////////////////////////////////////////////////////////////////////////

@import "../../assets/scss/Styles.scss";

// /////////////////////////////////////////////////////////////////////////////

#home-page {
  padding: 10px;

  display: flex;
  flex-direction: column;

  min-width: 100vw;
  min-height: 100vh;

  max-width: 100vw !important;
  height: 100vh !important;

  #title-pane {
    display: flex;
    flex-direction: column;
    align-items: center;

    #txt-welcome {
      color: $txt-b;
    }

    #img-name {
      max-height: 50px;
    }

    #txt-title {
      text-align: center;
      margin-top: 10px;
      color: $txt-b;
      font-family: $font-fam-title;
      font-weight: bold;
    }
  }

  #card-pane {
    flex-grow: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    .card {
      flex-grow: 1;
      min-height: 54vh;
      width: 100%;

      padding: 20px;
      margin: auto;
      border-radius: 10px;

      box-shadow: $box-shadow-card-hover;

      .card-text-pane {
        flex-grow: 2;

        .card-title {
          font-weight: bold;
          color: $txt-01;
          font-family: $font-fam-title;
        }

        .card-subtitle {
          font-weight: bold;
          font-style: italic;
          color: $txt-c;
          font-family: $font-fam-title;
        }

        .card-text {
          padding-top: 10px;
        }
      }

      .card-button-pane {
        display: flex;
        flex-direction: column;
        padding-top: 10px;
      }
    }

    #card-carousel {
      display: block;

      .carousel-inner {
        width: 100%;
        margin: auto;

        .carousel-item {
          padding: 30px;
          padding-bottom: 36px;
        }
      }

      $num: -10px;
      .carousel-control-prev {
        margin-left: $num;
      }

      .carousel-control-next {
        margin-right: $num;
      }

      .carousel-indicators {
        margin-bottom: -5px;
      }
    }

    #card-holder {
      display: none;
    }
  }

  // ///////////////////////////////////////////////////////////////////////////
  // Loader ////////////////////////////////////////////////////////////////////
  // ///////////////////////////////////////////////////////////////////////////

  .gooey {
    position: absolute;
    top: 50%;
    left: 50%;
    width: 142px;
    height: 40px;
    margin: -20px 0 0 -71px;
    background: #fff;
    filter: contrast(20);

    .dot {
      position: absolute;
      width: 16px;
      height: 16px;
      top: 12px;
      left: 15px;
      filter: blur(4px);
      background: #000;
      border-radius: 50%;
      transform: translateX(0);
      animation: dot 2.8s infinite;
    }

    .dots {
      transform: translateX(0);
      margin-top: 12px;
      margin-left: 31px;
      animation: dots 2.8s infinite;

      span {
        display: block;
        float: left;
        width: 16px;
        height: 16px;
        margin-left: 16px;
        filter: blur(4px);
        background: #000;
        border-radius: 50%;
      }
    }
  }

  @keyframes dot {
    50% {
      transform: translateX(96px);
    }
  }

  @keyframes dots {
    50% {
      transform: translateX(-31px);
    }
  }

  .loading {
    position: absolute;
    top: 54%;
    left: 50%;
    width: 200px;
    height: 40px;
    margin: -20px 0 0 -71px;
  }
}

@media (min-width: 768px) {
  #home-page {
    #title-pane {
      #img-name {
        margin-top: 20px;
        max-height: 80px;
      }

      #txt-title {
        max-width: 60vw;
      }
    }

    #card-pane {
      #card-carousel {
        display: none;
      }

      #card-holder {
        display: flex;
        flex-direction: row;
        align-items: stretch;
        gap: 20px;

        .card {
          width: 22vw;
          flex-grow: 1 !important;
        }
      }
    }
  }
}

// /////////////////////////////////////////////////////////////////////////////
// FLIP ////////////////////////////////////////////////////////////////////////
// /////////////////////////////////////////////////////////////////////////////

.back-only {
  background-color: $bgd-a;
  border-radius: 10px;
}

.flip-card {
  flex-grow: 1;
  width: 300px;
  min-height: 54vh;
  border-radius: 10px;

  box-shadow: $box-shadow-card-hover;
}

@media (min-width: 768px) {
  .flip-card {
    width: 22vw;
    flex-grow: 1 !important;
  }
}

.flip-card {
  overflow: visible;
}

.content {
  width: 100%;
  height: 100%;
  transform-style: preserve-3d;
  transition: transform 300ms;
  border-radius: 10px;
}

#home-page {
  #card-pane {
    .card-effect {
      margin: 0;
      background-color: transparent;
    }
  }
}

.front,
.back {
  background-color: white;
  position: absolute;
  width: 100%;
  height: 100%;
  backface-visibility: hidden;
  -webkit-backface-visibility: hidden;
  border-radius: 10px;
  overflow: hidden;
}

.back,
.front {
  height: 100%;
  justify-content: center;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.back::before,
.front::before {
  position: absolute;
  content: " ";
  display: block;
  width: 160px;
  height: 160%;
  background: linear-gradient(
    90deg,
    transparent,
    $bgd-01,
    $bgd-01,
    $bgd-01,
    $bgd-01,
    transparent
  );
  animation: rotation_481 5000ms infinite linear;
}

.effect {
  position: absolute;
  width: 98%;
  height: 98%;
  background-color: $bgd-a;
  border-radius: 10px;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 30px;
}

.flip-card:hover .content {
  transform: rotateY(180deg);
}

@keyframes rotation_481 {
  0% {
    transform: rotateZ(0deg);
  }

  0% {
    transform: rotateZ(360deg);
  }
}

.front {
  transform: rotateY(180deg);
  color: white;
}
